$(document).on('click', '#login-button', LogIn);
function LogIn(){
    var DataSend = {
        identification: $('#input-identification').val()
        ,password: $('#input-password').val()
    };
    PostMethodFunction('/app/user/login', DataSend, null, loginSuccess, null);
}
function loginSuccess(response){
    localStorage.removeItem("userData");
    if(response.access_urls.length>0){
        location.href = response.access_urls[0];
    }
}